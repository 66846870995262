import styled from '@emotion/styled'
import { Button } from '@mui/material'
import { AutocompleteRenderOptionState, AutocompleteRenderGroupParams } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'

import { isFromAppAtom } from '../../../state/common/atoms'
import { useDeepCompareEffect } from '../../hooks/useDeepCompare'
import AVSearch from '../../services/search/AVSearch'
import { breakpoint } from '../../utils/breakpoint'
import { scale } from '../../utils/scale'
import { ColumnFlex } from '../Layout/Layout'
import ModalHeader from '../Modal/ModalHeader/ModalHeader'
import { Text } from '../Typography/Typography'

import SearchAutocomplete, { AutocompleteSearchResult } from './SearchAutocomplete'
import SearchItems from './SearchItems'
import { SearchTreeItem } from './types'

const Container = styled(ColumnFlex)<{ isFromApp?: boolean }>`
  @media (max-width: ${breakpoint.sm}px) {
    padding: ${(props) => (props.isFromApp ? 0 : scale(3))} 0 0 0;
  }
`

const Heading = styled(Text)`
  padding: 16px;
`
Heading.defaultProps = { $size: 400, $weight: 'Medium', $height: 'Medium' }

const BreadcrumbHeading = styled(Text)`
  margin-top: ${scale(2)};
  margin-bottom: ${scale(2)};
  padding: 0 16px;
`
BreadcrumbHeading.defaultProps = { $size: 400, $weight: 'Medium', $height: 'Medium' }

const BreadcrumbDescription = styled(Text)`
  margin-bottom: ${scale(2)};
  padding: 0 16px;
`

BreadcrumbDescription.defaultProps = { $size: 300, $height: 'Large' }

const BreadcrumbError: React.FC<
  React.PropsWithChildren<{
    error?: string
    buttonText: string
    onButtonClick: () => void
  }>
> = ({ error, buttonText, onButtonClick }) => {
  if (!error) {
    return null
  }

  return (
    <ColumnFlex>
      <BreadcrumbDescription>{error}</BreadcrumbDescription>
      <div>
        <Button variant="outlined" onClick={onButtonClick}>
          {buttonText}
        </Button>
      </div>
    </ColumnFlex>
  )
}

const ShowPopularButton = styled(Button)`
  height: 40px;
  min-width: 176px;
  align-self: center;
`

const PendingContainer = styled(ColumnFlex)`
  gap: 16px;
  margin-bottom: 12px;
`

// eslint-disable-next-line @typescript-eslint/ban-types
interface Props<T extends object, V extends object> {
  popularData: Array<SearchTreeItem<T>>
  allData?: Array<SearchTreeItem<T>>
  searchIndex: AVSearch<V>
  breadcrumb: Array<SearchTreeItem<T>>
  renderAutocompleteOption(
    props: React.HTMLAttributes<HTMLLIElement>,
    option: V,
    state: AutocompleteRenderOptionState
  ): React.ReactNode
  onAutocompleteOptionSelect(option: V): void
  getOptionLabel(option: V): string
  optionListModifier?(
    options: Array<V & AutocompleteSearchResult>
  ): Array<V & AutocompleteSearchResult>
  searchResult: Array<SearchTreeItem<T>> | null
  popularHeading?: string
  popularLabel?: string
  allDataHeading?: string
  allDataLabel?: string
  searchPending?: boolean
  placeholder?: string
  searchFieldLabel: string
  backLabel?: string
  onBreadcrumbChange(
    updaterOrBreadcrumb:
      | ((currVal: Array<SearchTreeItem<T>>) => Array<SearchTreeItem<T>>)
      | Array<SearchTreeItem<T>>
  ): void
  onSelectAndClose(item: T): void
  onClose(): void
  iconProvider?(item: SearchTreeItem<T>): JSX.Element | string
  noteProvider?(item: SearchTreeItem<T>): JSX.Element
  useTracking?: boolean
  renderGroup?(params: AutocompleteRenderGroupParams): React.ReactNode
  groupBy?(option: V): string
  open: boolean
  renderItem?(
    item: SearchTreeItem<T>,
    onSelect: () => void,
    attr?: React.ButtonHTMLAttributes<HTMLButtonElement>,
    dataset?: Record<string, string>
  ): JSX.Element
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Search = <T extends object, V extends object>({
  popularData,
  allData,
  searchIndex,
  breadcrumb,
  renderAutocompleteOption,
  onAutocompleteOptionSelect,
  optionListModifier,
  getOptionLabel,
  popularHeading,
  popularLabel,
  allDataHeading,
  allDataLabel,
  backLabel,
  searchResult,
  onBreadcrumbChange,
  onSelectAndClose,
  onClose: onCloseOriginal,
  iconProvider,
  noteProvider,
  searchPending,
  placeholder,
  searchFieldLabel,
  useTracking,
  renderGroup,
  groupBy,
  open,
  renderItem,
}: Props<T, V>): JSX.Element => {
  const { t } = useTranslation()
  const isFromApp = useRecoilValue(isFromAppAtom)
  const [showAllData, setShowAllData] = useState<boolean>(false)
  const [selectedNoteItem, setSelectedNoteItem] = useState<SearchTreeItem<T> | null>(null)
  const [isBreadcrumbUpdatedFromSearchResult, setIsBreadcrumbUpdatedFromSearchResult] =
    useState<boolean>(false)

  const onClose = useCallback(() => {
    onCloseOriginal()
  }, [onCloseOriginal])

  useDeepCompareEffect(() => {
    setIsBreadcrumbUpdatedFromSearchResult(false)
  }, [searchResult, searchPending])

  const subHeadingRef = useRef<HTMLHeadingElement>(null)

  const onSearchTreeItemSelect = useCallback(
    (item: SearchTreeItem<T>) => {
      if (item.note) {
        setSelectedNoteItem(item)
        onBreadcrumbChange((previous) => [...previous, item])
        return
      }

      if (item.item && (!item.children || !item.children.length)) {
        onSelectAndClose(item.item)
        return
      }

      if (!item.children || !item.children.length) {
        onClose()
        return
      }

      onBreadcrumbChange((previous) => [...previous, item])
      setTimeout(() => subHeadingRef.current?.focus())
    },
    [onBreadcrumbChange, onClose, onSelectAndClose]
  )

  useEffect(() => {
    if (searchResult && searchResult.length === 1) {
      const onlySearchResult = searchResult[0]
      if (
        onlySearchResult.children &&
        onlySearchResult.children.length > 0 &&
        !isBreadcrumbUpdatedFromSearchResult &&
        (breadcrumb.length === 0 || breadcrumb[0].id !== onlySearchResult.id)
      ) {
        setIsBreadcrumbUpdatedFromSearchResult(true)
        onBreadcrumbChange([onlySearchResult])
      }

      if (!onlySearchResult.children || onlySearchResult.children.length === 0) {
        onSearchTreeItemSelect(onlySearchResult)
      }
    }
  }, [
    isBreadcrumbUpdatedFromSearchResult,
    searchResult,
    onBreadcrumbChange,
    onAutocompleteOptionSelect,
    onSearchTreeItemSelect,
    breadcrumb,
  ])

  const getBreadcrumbName = useCallback(() => {
    return breadcrumb.length > 0 ? breadcrumb.slice(-1)[0].name : undefined
  }, [breadcrumb])

  const getBreadcrumbChildren = useCallback(() => {
    return breadcrumb.length > 0 ? breadcrumb.slice(-1)[0].children : undefined
  }, [breadcrumb])

  const getBreadcrumbDescription = useCallback(() => {
    return breadcrumb.length > 0 ? breadcrumb.slice(-1)[0].description : undefined
  }, [breadcrumb])

  const getBreadcrumbError = useCallback(() => {
    return breadcrumb.length > 0 ? breadcrumb.slice(-1)[0].error : undefined
  }, [breadcrumb])

  const popBreadcrumb = useCallback(() => {
    onBreadcrumbChange((previous) => previous.slice(0, -1))
  }, [onBreadcrumbChange])

  const popNote = useCallback(() => {
    setSelectedNoteItem(null)
    popBreadcrumb()
  }, [popBreadcrumb])

  return (
    <Container isFromApp={isFromApp}>
      {searchPending ? (
        <>
          <ModalHeader onClose={onClose} onBack={popBreadcrumb} enableMobileClose={open} />
          <SearchAutocomplete
            label={searchFieldLabel}
            placeholder={placeholder}
            searchIndex={searchIndex}
            onAutocompleteOptionSelect={onAutocompleteOptionSelect}
            renderAutocompleteOption={renderAutocompleteOption}
            getOptionLabel={getOptionLabel}
            optionListModifier={optionListModifier}
            useTracking={useTracking}
            renderGroup={renderGroup}
            groupBy={groupBy}
          />
          <PendingContainer>
            <Heading as="h2">{t('component.search.resultsHeading')}</Heading>
            {[...new Array(4)].map((_, index) => (
              <Skeleton
                key={`skeleton-${index}`}
                variant="rectangular"
                width="100%"
                height="48px"
              />
            ))}
          </PendingContainer>
        </>
      ) : (
        <>
          {selectedNoteItem && noteProvider ? (
            <>
              <ModalHeader
                onClose={onClose}
                onBack={popNote}
                enableMobileClose={open}
                backLabel={backLabel}
              />
              {noteProvider(selectedNoteItem)}
            </>
          ) : (
            <>
              <ModalHeader
                onClose={onClose}
                onBack={breadcrumb.length > 0 ? popBreadcrumb : undefined}
                enableMobileClose={open}
                backLabel={backLabel}
              />
              <SearchAutocomplete
                label={searchFieldLabel}
                placeholder={placeholder}
                searchIndex={searchIndex}
                onAutocompleteOptionSelect={onAutocompleteOptionSelect}
                renderAutocompleteOption={renderAutocompleteOption}
                getOptionLabel={getOptionLabel}
                optionListModifier={optionListModifier}
                useTracking={useTracking}
                renderGroup={renderGroup}
                groupBy={groupBy}
              />
              {searchResult?.length === 0 && breadcrumb.length === 0 && !allData && (
                <ColumnFlex>
                  <Heading as="h2">
                    {popularHeading ?? t('component.search.popularHeading')}
                  </Heading>
                  <SearchItems
                    items={popularData}
                    onSelect={onSearchTreeItemSelect}
                    iconProvider={iconProvider}
                    renderItem={renderItem}
                  />
                </ColumnFlex>
              )}
              {(!searchResult || searchResult.length === 0) &&
                breadcrumb.length === 0 &&
                allData && (
                  <ColumnFlex>
                    <Heading as="h2">
                      {showAllData
                        ? allDataHeading ?? t('component.search.allDataHeading')
                        : popularHeading ?? t('component.search.popularHeading')}
                    </Heading>
                    <SearchItems
                      items={showAllData ? allData : popularData}
                      onSelect={onSearchTreeItemSelect}
                      iconProvider={iconProvider}
                      useTracking={useTracking}
                      renderItem={renderItem}
                    />
                    <ShowPopularButton
                      variant="outlined"
                      onClick={() => setShowAllData(!showAllData)}
                      data-cy="search-showPopularButton"
                    >
                      {showAllData
                        ? popularLabel ?? t('component.search.popularLink')
                        : allDataLabel ?? t('component.search.allServices')}
                    </ShowPopularButton>
                  </ColumnFlex>
                )}
              {searchResult && searchResult.length > 0 && breadcrumb.length === 0 && (
                <ColumnFlex>
                  <Heading as="h2">{t('component.search.resultsHeading')}</Heading>
                  <SearchItems
                    items={searchResult}
                    onSelect={onSearchTreeItemSelect}
                    iconProvider={iconProvider}
                    renderItem={renderItem}
                  />
                </ColumnFlex>
              )}
              {breadcrumb.length > 0 && (
                <ColumnFlex>
                  <BreadcrumbHeading as="h2" tabIndex={-1} ref={subHeadingRef}>
                    {getBreadcrumbName()}
                  </BreadcrumbHeading>
                  {getBreadcrumbDescription() && (
                    <BreadcrumbDescription>{getBreadcrumbDescription()}</BreadcrumbDescription>
                  )}
                  <BreadcrumbError
                    error={getBreadcrumbError()}
                    onButtonClick={popBreadcrumb}
                    buttonText={t('common.back')}
                  />
                  <SearchItems
                    items={
                      getBreadcrumbChildren()?.map((i) => {
                        // omit the parentName as it's the same for all child items
                        return { ...i, parentName: '' }
                      }) ?? []
                    }
                    onSelect={onSearchTreeItemSelect}
                    iconProvider={iconProvider}
                    useTracking={useTracking}
                    parentName={getBreadcrumbName()}
                    renderItem={renderItem}
                  />
                </ColumnFlex>
              )}
            </>
          )}
        </>
      )}
    </Container>
  )
}

export default Search
